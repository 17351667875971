import React, {
  CSSProperties,
  Dispatch,
  ElementType,
  FC,
  MouseEvent,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { FormRenderProps } from 'react-final-form'
import { useSwitch } from '@dasreda/hooks'
import { FormSubscription } from 'final-form'
import { agreementDocuments_agreementDocuments_AgreementDocument as TAgreementDocument } from 'gql/queries/generated/agreementDocuments'
import { History, LocationState } from 'history'

import { Alert } from 'components'

import { ICaptchaInfo } from '../../index'
import { SFieldsBlock, SForm, SFormExpand, SFormTitle, SFormWrapper, SLeftColumn } from '../../styled.index'
import FieldRender, { TField } from '../Field'
import SubmitButton from '../SubmitButton'

export type TPreparedAgreeDocuments = { [key: string]: TAgreementDocument }

export type TVerticalAlign = 'flex-start' | 'center'

export interface IRenderForm {
  // Список полей формы
  fields: TField[]
  // Заголовок формы
  title?: string | ReactElement | null
  // Уровень заголовка формы
  titleLevel?: 0 | 1 | 2 | 3 | 4 | 5
  // Содержимое левой колонки
  leftColumn?: ReactElement
  // Ширина формы
  formWidth?: number
  // Текст для кнопки submit'a
  submitButtonText?: string
  // Сбросить капчу после n неудачных попыток cабмита формы
  resetCaptchaCount?: number
  // Функция кастомного рендера блока submit
  // @param - {form, submitting, submitSucceeded, submitFailed, hasValidationErrors, history, setError}, где
  // form - нативные свойства формы
  // submitting, submitSucceeded, submitFailed, hasValidationErrors - https://final-form.org/docs/final-form/types/FormState
  // history - из react-router-dom
  // setError(string) - кастомное описание ошибки
  // @return - Element, jsx для блока submit
  submitBlock?: ({
    form,
    submitting,
    hasValidationErrors,
    submitSucceeded,
    setError,
    agreementDocuments,
    history,
  }: Pick<FormRenderProps, 'form'> &
    Pick<FormSubscription, 'submitting' | 'hasValidationErrors' | 'submitSucceeded'> & {
      history: History<LocationState>
      setError: Dispatch<React.SetStateAction<string | null>>
      setSucceeded: Dispatch<React.SetStateAction<boolean | null>>
      agreementDocuments: TPreparedAgreeDocuments
    }) => ReactElement | null
  // Блок, в котором будет выведена разметка перед блоком формы (например, пояснения к форме)
  header?: ReactElement | string
  // Блок, в котором будет выведена разметка перед элементами формы (например, кнопки сторонних провайдеров)
  insideHeader?: ReactElement | string
  // Блок, в котором будет выведена разметка после блока формы (например, статические соглашения)
  footer?: ReactElement | string
  // Блок, в котором будет выведена разметка после элементов формы (например, ссылка восстановить пароль)
  insideFooter?: ReactElement | string
  expanded?: boolean
  expand?: () => void
  withExpand?: boolean
  expandText?: string
  className?: string
  // Блок со статическим текстом, будет показан при успешном submit'e
  successMessage?: string | ElementType
  // Блок со статическим текстом, будет показан при неуспешном submit'e вместе с ошибками
  failureMessage?: string | ElementType
  // Вертикальное выравнивание
  verticalAlign?: TVerticalAlign
  // Сброс каптчи при достижение максимального количества ошибок после сабмита
  resetCaptcha?: {
    captchaInfo: ICaptchaInfo
    setCaptchaInfo: (value: ICaptchaInfo) => void
  }
}

type TParentProps = {
  history: History<LocationState>
  agreementDocuments: TPreparedAgreeDocuments
}

type TRenderForm = IRenderForm & FormRenderProps

const RenderForm: FC<TRenderForm & TParentProps> = ({
  // IRenderForm
  fields,
  title = null,
  titleLevel = 1,
  leftColumn,
  formWidth,
  submitButtonText = 'Отправить',
  submitBlock: SubmitBlock = undefined,
  header = '',
  insideHeader = '',
  footer = '',
  insideFooter = '',
  agreementDocuments,
  expanded = false,
  expand = () => {},
  withExpand = false,
  expandText = '',
  successMessage = '',
  failureMessage = '',
  verticalAlign = 'center',
  // FormRenderProps
  form,
  handleSubmit,
  submitError,
  hasValidationErrors,
  submitting,
  submitSucceeded,
  submitFailed,
  // RouteComponentProps
  history,
  resetCaptcha,
}) => {
  const [error, setError] = useState<string | null>(null)
  const [succeeded, setSucceeded] = useState<boolean | null>(null)
  const isExpanded = useMemo(() => !withExpand || (withExpand && expanded), [withExpand, expanded])
  const collapsed = useMemo(() => withExpand && !expanded, [withExpand, expanded])

  const renderSubmitBlock = useMemo(() => {
    if (SubmitBlock) {
      return (
        <SubmitBlock
          {...{
            form,
            submitting,
            hasValidationErrors,
            submitSucceeded,
            setError,
            setSucceeded,
            agreementDocuments,
            history,
          }}
        />
      )
    }

    return (
      <SubmitButton type="submit" disabled={submitting || hasValidationErrors}>
        {submitButtonText}
      </SubmitButton>
    )
  }, [
    SubmitBlock,
    submitButtonText,
    form,
    submitting,
    hasValidationErrors,
    submitSucceeded,
    setError,
    agreementDocuments,
    history,
  ])

  const handleExpand = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault()
      expand()
    },
    [expand]
  )

  const [isFormPasswordMasksDisabled, switchFormPasswordMasksDisabledState] = useSwitch(false)

  // memoize fields can be potential problem,
  // but we don't need to rerender fields when form props was changed
  const fieldsBlock = useMemo(
    () =>
      fields.map((field, index) => {
        const fieldProps = { index, ...field }
        if (field.name === 'oauth') {
          fieldProps.subscription = {}
          fieldProps.hasSeparator = withExpand && expanded
        }

        return (
          <FieldRender
            key={field.name}
            hidden={collapsed && field.name !== 'oauth' && field.type !== 'checkbox'}
            resetCaptcha={resetCaptcha}
            isFormPasswordMasksDisabled={isFormPasswordMasksDisabled}
            switchFormPasswordMasksDisabledState={switchFormPasswordMasksDisabledState}
            {...fieldProps}
          />
        )
      }),
    [fields, withExpand, expanded, resetCaptcha, isFormPasswordMasksDisabled]
  )

  const style = useMemo(
    () =>
      ({
        '--form-width': formWidth ? `${formWidth}px` : undefined,
      } as CSSProperties),
    [formWidth]
  )

  const renderBody = () => (
    <>
      {title && <SFormTitle level={titleLevel}>{title}</SFormTitle>}
      {header}
      <SForm onSubmit={handleSubmit}>
        {insideHeader}
        {(succeeded || submitSucceeded) && successMessage && <Alert text={successMessage} type="success" />}
        {(error || (submitFailed && (submitError || failureMessage))) && (
          <Alert
            text={
              <>
                {failureMessage && (
                  <>
                    {failureMessage}
                    <br />
                    <br />
                  </>
                )}
                {submitError || error}
              </>
            }
            type="danger"
          />
        )}
        {isExpanded && <SFieldsBlock>{fieldsBlock}</SFieldsBlock>}
        {isExpanded && insideFooter}
        {isExpanded && renderSubmitBlock}
        {withExpand && !expanded && (
          <SFormExpand to="#expand" onClick={handleExpand}>
            {expandText}
          </SFormExpand>
        )}
      </SForm>
      {footer}
    </>
  )

  if (leftColumn) {
    return (
      <SFormWrapper $verticalAlign={verticalAlign} $hasLeftColumn={!!leftColumn} style={style}>
        {leftColumn && <SLeftColumn>{leftColumn}</SLeftColumn>}
        <div>{renderBody()}</div>
      </SFormWrapper>
    )
  }

  return (
    <SFormWrapper $verticalAlign={verticalAlign} $hasLeftColumn={!!leftColumn} style={style}>
      {renderBody()}
    </SFormWrapper>
  )
}

export default RenderForm
