export interface IHeader {
  type: keyof typeof config
}

export const config: TConfig = {
  login: {
    title: 'Зарегистрироваться',
    link: '/registration',
    description: 'Нет аккаунта?',
  },
  'login-by-phone': {
    title: 'Зарегистрироваться',
    link: '/registration/phone',
    description: 'Нет аккаунта?',
  },
  'login-by-email': {
    title: 'Зарегистрироваться',
    link: '/registration/email',
    description: 'Нет аккаунта?',
  },
  'login-sbbol': {
    title: 'Зарегистрироваться',
    link: '/registration/sbbol',
    description: 'Нет аккаунта?',
  },
  registration: {
    title: 'Войти',
    link: '/login',
    description: 'Есть аккаунт?',
  },
  'registration-domclick': {},
  'registration-by-phone': {
    title: 'Войти',
    link: '/login-by-phone',
    description: 'Есть аккаунт?',
  },
  'registration-sbbol': {
    title: 'Войти',
    link: '/login/sbbol',
    description: 'Есть аккаунт?',
  },
  'password-reset-email': {
    description: 'Укажите адрес эл.почты, на который мы отправим ссылку для восстановления пароля',
    onlyText: true,
  },
  'password-reset-phone': {
    description: 'Укажите номер телефона, на который мы отправим код для восстановления пароля',
    onlyText: true,
  },
  'password-change-phone': {
    description:
      'Введите код, который мы вам отправили в СМС, а так же придумайте новый пароль и введите его в поле ниже',
    onlyText: true,
  },
  'password-change': {
    description: 'Придумайте новый пароль и введите его в поле ниже, чтобы изменить настройки',
    onlyText: true,
  },
  'password-change-actual-policy': {
    description: 'Придумайте новый пароль, который соответствует актуальной парольной политике',
    onlyText: true,
  },
  'biz-adaptation': {
    description: 'Отправить клиенту ссылки и подробную информацию о программе обучения',
    onlyText: true,
  },
}

type TConfig = {
  [key: string]: {
    title?: string
    link?: string
    description?: string
    onlyText?: boolean
  }
}

export interface ISHeaderWrapperProps {
  onlyText?: boolean
}
