import isEmpty from 'lodash/isEmpty'

import { configs } from 'eco-api'

import StatusInfo, { IStatusInfo } from 'components/Form/Header/profile'
import { SUBSCRIPTION_DISPATCH } from 'configs/agreements'
import { dummyUser, REDIRECT_URL } from 'configs/consts'
import { DATALAYER_CUSTOM_EVENT, DATALAYER_EVENT_AUTHORIZATION } from 'configs/dataLayer'
import { TFormConfig, TFormConfigFn } from 'containers/FormBuilder'
import { TField, TValues } from 'containers/FormBuilder/components/Field'
import { updateUserAgreementRequest } from 'utils/agreements'
import { getUserByStorage, logout, setUserToStorage, updateUserData, updateUserPesonalData } from 'utils/auth'
import getFormFieldsByType from 'utils/getFormFieldsByType'
import { sendNewUserToGTR } from 'utils/getResponse'
import { normalizeDummyName } from 'utils/normalize'
import { redirect } from 'utils/redirect'
import { dataLayerHandler } from 'utils/sendDataLayer'
import { email as emailValidator, phone as phoneValidator, required } from 'utils/validators'

import { emailFieldProps, nameFieldProps, phoneFieldProps, subscriptionAgree } from '../../defaultFieldsProps'

const { resolvePortalUrl } = configs

const config = {
  subscription: {
    submitButtonText: 'Перейти к оплате подписки',
    extraFields: [
      {
        ...subscriptionAgree,
        validators: [required],
        index: 2,
      },
    ],
    initialValues: { [SUBSCRIPTION_DISPATCH]: true },
  },
  oAuth: {
    submitButtonText: 'Перейти к авторизации',
    extraFields: [
      {
        ...emailFieldProps,
        validators: [required, emailValidator],
        index: 2,
      },
      {
        ...phoneFieldProps,
        validators: [required, phoneValidator],
        index: 3,
      },
    ],
  },
  default: {
    submitButtonText: 'Перейти на платформу',
    successRedirect: `${resolvePortalUrl()}user-profile`,
  },
}

type TPartialFormConfig = Partial<Pick<TFormConfig, 'submitButtonText'>>
interface IReturnConfig extends TPartialFormConfig {
  successRedirect?: string
  initialValues?: TValues
  extraFields?: TField[]
}

const concatExtraFields = (baseArray: TField[], extraFields: TField[]) => {
  extraFields.forEach((field) => {
    baseArray.splice(field.index as number, 0, field)
  })

  return baseArray
}

const profileChange: TFormConfigFn<{
  type: string
  form: IStatusInfo['type']
  email: string
  phone: string
  firstName: string
  lastName: string
}> = ({
  location: {
    query: {
      type = 'default',
      form = 'email',
      email: initialEmail = '',
      phone: initialPhone = '',
      firstName = dummyUser.firstName,
      lastName = dummyUser.lastName,
    },
  },
}) => {
  const {
    submitButtonText,
    extraFields = [],
    successRedirect,
    initialValues: extraInitialValues,
  } = getFormFieldsByType<IReturnConfig>(config)(type)

  return {
    title: '',
    submitButtonText,
    withLogo: false,
    header: <StatusInfo type={form} />,
    initialValues: {
      username: initialEmail,
      phone: initialPhone,
      ...extraInitialValues,
    },
    fields: concatExtraFields(
      [
        {
          ...nameFieldProps,
          name: 'publicName',
          placeholder: 'Публичное имя',
          validators: [required, ...(nameFieldProps.validators || [])],
          // TODO: заставить работать при первом рендере, чтобы не показывать черточки для сущ. пользователей
          parse: normalizeDummyName,
        },
      ],
      extraFields
    ),
    postRender: ({ history }) => {
      if (isEmpty(getUserByStorage())) history.goBack()
    },
    onRequest: async ({ subscription_dispatch, publicName, phone, username: email }) => {
      try {
        const user = getUserByStorage()
        const nextUser = { ...user, publicName }
        const { id, channelId } = nextUser

        await sendNewUserToGTR(nextUser)

        if (subscription_dispatch) {
          await updateUserAgreementRequest({ type: SUBSCRIPTION_DISPATCH, agreed: subscription_dispatch })
        }

        await updateUserData(nextUser)

        if (id) {
          dataLayerHandler({
            event: DATALAYER_CUSTOM_EVENT,
            eventCategory: DATALAYER_EVENT_AUTHORIZATION,
            eventAction: channelId,
            userId: id,
          })
        }

        if (phone || email) {
          await updateUserPesonalData({ phone, email, id: nextUser.id, firstName, lastName })
        }

        setUserToStorage(nextUser)
        /** Записывать данные с помощью setAuth необходимо, т.к. они нужны для выполнения запросов аналитики при первой
         * авторизации пользователя. В конце вызываем logout для того, чтобы не происходил автологин. */
        logout()
        return true
      } catch (e) {
        return false
      }
    },
    postHook: (_, response, { history: { push } }) => {
      const queriesFromStorage = localStorage.getItem(REDIRECT_URL)?.split('?')[1]

      if (response) {
        redirect({ push, url: queriesFromStorage ? `/login?${queriesFromStorage}` : successRedirect })
      }
    },
  }
}

export default profileChange
