import { Cookies } from 'react-cookie'
import { TRequest } from 'types/api'
import { TResUser } from 'types/user'

import { configs, helpers } from 'eco-api'

import { Footer, Header } from 'components'
import { TFormConfigFn } from 'containers/FormBuilder'
import { TValues } from 'containers/FormBuilder/components/Field'
import { request } from 'utils/api'
import { login, updateUserData } from 'utils/auth'
import { isValidUrl } from 'utils/isValidUrl'
import { redirect } from 'utils/redirect'
import {
  equal,
  passwordPolicyCheckCyrillic,
  passwordPolicyCheckForbiddenChars,
  required,
  threeStepPasswordPolicyValidator,
} from 'utils/validators'
import { generateMaxLengthValidator, generateMinLengthValidator } from 'utils/validatorsGenerators'

import { SFieldInput } from '../../../containers/FormBuilder/styled.index'
import { passwordFieldProps, passwordRepeatFieldProps } from '../../defaultFieldsProps'

const { AUTH_COOKIE_NAME, AUTH_ADMIN_COOKIE_NAME, resolvePortalUrl } = configs
const { decodeToken } = helpers
const passwordChangeActualPolicy: TFormConfigFn = ({ location, history: { push } }) => {
  const { admin } = location.query

  const cookies = new Cookies()
  let token: string

  if (admin) {
    token = cookies.get(AUTH_ADMIN_COOKIE_NAME)
  } else {
    token = cookies.get(AUTH_COOKIE_NAME)
  }

  if (!token) {
    redirect({
      push,
      url: '/login',
    })
  }

  const username = decodeToken(token)?.sub || ''

  return {
    title: 'Сменить пароль',
    header: <Header type="password-change-actual-policy" />,
    submitButtonText: 'Сменить пароль',
    initialValues: {
      username,
    },
    fields: [
      {
        name: 'username',
        disabled: true,
        component: SFieldInput,
        qa: 'password_uuid_field',
        mask: username.includes('@') ? undefined : 'phone',
      },
      {
        ...passwordFieldProps,
        focus: true,
        name: 'currentPassword',
        qa: 'password_request_field',
        placeholder: 'Текущий пароль',
        validators: [required],
      },
      {
        ...passwordFieldProps,
        placeholder: 'Новый пароль',
        validators: [
          required(undefined, undefined, 'Поле не заполнено'),
          generateMinLengthValidator(12, 'Пароль менее 12 символов не подходит'),
          generateMaxLengthValidator(64, 'Пароль более 64 символов не подходит'),
          passwordPolicyCheckCyrillic,
          passwordPolicyCheckForbiddenChars,
          threeStepPasswordPolicyValidator,
        ],
      },
      {
        ...passwordRepeatFieldProps,
        placeholder: 'Повторите новый пароль',
        validators: [required, (value, allValues: TValues) => equal(value, allValues, 'password')],
      },
    ],
    onRequest: async (values) => {
      const { currentPassword: oldPassword, password: newPassword } = values

      const changesPasswordReqParams: TRequest = {
        location: 'core-router/v2:user/password',
        method: 'post',
        params: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
          data: {
            oldPassword,
            newPassword,
            anyLogin: username,
          },
        },
      }

      const loginReqParams = {
        location: 'single-auth:login',
        params: {
          withCredentials: true,
          data: {
            username,
            password: newPassword,
          },
        },
      }

      await request(changesPasswordReqParams)

      if (admin) {
        return null
      }

      return login(loginReqParams)
    },
    postHook: async (error, response) => {
      if (response) {
        const { data: user } = response as TResUser

        await updateUserData({ ...user, isFirstLogin: false })
      }

      if (!error) {
        const { target: targetUrlParam, redirect: redirectUrlParam } = location.query

        let targetUrl: string = targetUrlParam || redirectUrlParam

        if (!isValidUrl(targetUrl)) {
          targetUrl = resolvePortalUrl()
        }

        redirect({
          push,
          url: targetUrl,
        })
      }
    },
    footer: <Footer type="resetPassword" align="center" />,
  }
}

export default passwordChangeActualPolicy
