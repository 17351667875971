import qs from 'qs'

import { configs, helpers } from 'eco-api'

import { REDIRECT_URL } from 'configs/consts'
import { logout } from 'utils/auth'

import { isCurrentDomain } from './isCurrentDomain'
import { isExternalUrl } from './isExternalUrl'
import { isValidUrl } from './isValidUrl'

const { resolvePortalUrl } = configs

const { isAuthenticated, getAuthToken } = helpers

export const redirectUrlNormalize = (url) => {
  if (!url) return url

  const [location, query] = url.split('?')
  const { options } = qs.parse(query)
  if (options) {
    const variants = options.split(',')
    const opt = variants.reduce((acc, conf, index) => {
      const [key, value] = conf.split(':')
      if (key && value) {
        return `${acc}${key}=${value}${variants.length - 1 > index ? '&' : ''}`
      }
      return acc
    }, '?')
    return `${location}${opt}`
  }
  return url
}

/**
 *
 * @param {*} params
 * @param {*} state
 * @returns
 */
export function redirect(params = {}, state = undefined) {
  const { url, push } = params
  let redirectToUrl = url || redirectUrlNormalize(localStorage.getItem(REDIRECT_URL)) || resolvePortalUrl()
  const { redirect: redirectParam, ...paramsWithoutRedirect } = qs.parse(redirectToUrl)

  if (!isExternalUrl(redirectToUrl) && !push) {
    console.warn('if redirect url has local address you should send push() to arguments')
    return
  }

  if (redirectToUrl.startsWith('/')) {
    if (redirectParam && !isCurrentDomain(redirectParam.toString())) {
      redirectToUrl = decodeURIComponent(qs.stringify(paramsWithoutRedirect))
    }

    push(redirectToUrl, state)
    return
  }

  if (!isValidUrl(redirectToUrl)) {
    window.location = resolvePortalUrl()
    return
  }

  window.location = redirectToUrl
}

export const redirectPreRenderHook = async ({ location: { query } }) => {
  const { REACT_APP_DISABLE_AUTH_REDIRECT } = process.env
  const params = query?.redirect ? { url: query.redirect } : undefined
  const { applypd } = query

  if (isAuthenticated() && (!REACT_APP_DISABLE_AUTH_REDIRECT || !(REACT_APP_DISABLE_AUTH_REDIRECT === 'true'))) {
    const token = getAuthToken()

    if (applypd === 'true' && token) {
      logout()
    } else {
      redirect(params)
    }
  }
}

export const getDomainFromRedirectLink = (url = '') => {
  return url.match(/^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/gim)
}
