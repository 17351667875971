import { TRequest } from 'types/api'
import { TResUser } from 'types/user'

import { ISingleAuthLoginData } from 'configs/forms/platform/defaultLogin/types'

import { Header } from 'components'
import { TFormConfig } from 'containers/FormBuilder'
import { TValues } from 'containers/FormBuilder/components/Field'
import { SFieldInput } from 'containers/FormBuilder/styled.index'
import { request } from 'utils/api'
import { login, updateUserData } from 'utils/auth'
import { redirect } from 'utils/redirect'
import {
  equal,
  passwordPolicyCheckCyrillic,
  passwordPolicyCheckForbiddenChars,
  required,
  threeStepPasswordPolicyValidator,
} from 'utils/validators'
import { generateMaxLengthValidator, generateMinLengthValidator } from 'utils/validatorsGenerators'

import { passwordFieldProps, passwordRepeatFieldProps } from '../../defaultFieldsProps'

// TODO: продумать ошибку со скрытым полем uuid
const passwordChange: TFormConfig = {
  title: 'Смена пароля',
  header: <Header type="password-change" />,
  submitButtonText: 'Сохранить новый пароль',
  fields: [
    {
      name: 'email',
      type: 'hidden',
      component: SFieldInput,
      qa: 'password_uuid_field',
    },
    {
      name: 'code',
      type: 'hidden',
      component: SFieldInput,
      qa: 'password_uuid_field',
    },
    {
      ...passwordFieldProps,
      validators: [
        required(undefined, undefined, 'Поле не заполнено'),
        generateMinLengthValidator(12, 'Пароль менее 12 символов не подходит'),
        generateMaxLengthValidator(64, 'Пароль более 64 символов не подходит'),
        passwordPolicyCheckCyrillic,
        passwordPolicyCheckForbiddenChars,
        threeStepPasswordPolicyValidator,
      ],
    },
    {
      ...passwordRepeatFieldProps,
      validators: [(value, allValues: TValues) => equal(value, allValues, 'password')],
    },
  ],
  successMessage: 'Вы успешно сменили пароль',
  onRequest: (values) => {
    const config: TRequest = {
      location: 'core-router/v2:user/restore/email/confirm',
      method: 'post',
      params: {
        data: values,
      },
    }

    return request(config)
  },

  postHook: async (_error, _response, { initialValues: { email }, history: { push } }, { password }) => {
    const data: ISingleAuthLoginData = { password, username: email }

    const reqParams = {
      location: 'single-auth:login',
      params: {
        data,
      },
    }
    const { data: user } = (await login(reqParams)) as TResUser

    if (user?.isFirstLogin) {
      await updateUserData(
        { ...user, isFirstLogin: false },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
    }

    redirect({ push, url: `/login?username=${email}` })
  },
}

export default passwordChange
