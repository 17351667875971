import { configs } from 'eco-api'

import Sbbol115FZProvider from 'containers/OAuth/containers/Consumer/Sbbol115FZ'
import Sbbol120SecProvider from 'containers/OAuth/containers/Consumer/Sbbol120Sec'
import SbbolLMSProvider from 'containers/OAuth/containers/Consumer/SbbolLMS'
import SbbolPremiumProvider from 'containers/OAuth/containers/Consumer/SbbolPremium'

const env = configs.resolveEnvironment()

const defaultRoutes = [
  {
    path: '/',
    exact: true,
    configName: 'defaultLogin',
  },
  {
    path: '/login',
    exact: true,
    configName: 'defaultLogin',
  },
  {
    path: '/login-by-phone',
    exact: true,
    configName: 'loginByPhone',
  },
  {
    path: '/registration',
    exact: true,
    configName: 'defaultFastRegistration',
  },
  {
    path: '/registration-long',
    exact: true,
    configName: 'defaultRegistration',
  },
  {
    path: '/registration-domclick',
    exact: true,
    configName: 'domclickRegistration',
  },
  {
    path: '/registration/email',
    exact: true,
    configName: 'emailRegistration',
  },
  {
    path: '/registration/phone',
    exact: true,
    configName: 'phoneRegistration',
  },
  {
    path: '/registration-secondary/msp-ckr',
    exact: true,
    configName: 'mspRegistration',
  },
  {
    path: '/registration-secondary/572c69c6-4f6c-4156-81d7-bfad6222086e',
    exact: true,
    configName: 'mspRegistrationClone',
  },
  {
    path: '/registration-secondary/msp-bot',
    exact: true,
    configName: 'mspRegistrationBot',
  },
  {
    path: '/registration-secondary/msp-bot-may',
    exact: true,
    configName: 'mspRegistrationBotMay',
  },
  {
    path: '/registration-secondary/msp-bot-june',
    exact: true,
    configName: 'mspRegistrationBotJune',
  },
  {
    path: '/registration-secondary/msp-bot-july',
    exact: true,
    configName: 'mspRegistrationBotJuly',
  },
  {
    path: '/registration-secondary/msp-bot-september',
    exact: true,
    configName: 'mspRegistrationBotSeptember',
  },
  {
    path: '/registration-secondary/msp-bot-october',
    exact: true,
    configName: 'mspRegistrationBotOctober',
  },
  {
    path: '/registration-secondary/subscription',
    exact: true,
    configName: 'kmSubscriptionRegistration',
  },
  {
    path: '/registration-secondary/subscription/new-user',
    exact: true,
    configName: 'kmSubscriptionNewUser',
  },
  {
    path: '/registration-secondary/subscription/existing-user',
    exact: true,
    configName: 'kmSubscriptionExistingUser',
  },
  {
    path: '/registration-secondary/mfc',
    exact: true,
    configName: 'mfcRegistration',
  },
  {
    path: '/password-reset-request',
    exact: true,
    configName: 'passwordResetEmail',
  },
  {
    path: '/password-reset-request/email',
    exact: true,
    configName: 'passwordResetEmail',
  },
  {
    path: '/password-reset-request/phone',
    exact: true,
    configName: 'passwordResetPhone',
  },
  {
    path: '/password-change/:email/:code',
    exact: true,
    configName: 'passwordChange',
  },
  {
    path: '/password-change-phone',
    exact: true,
    configName: 'passwordChangePhone',
  },
  {
    path: '/password-change',
    exact: true,
    configName: 'passwordChangeActualPolicy',
  },
  {
    path: '/profile-change',
    exact: true,
    configName: 'profileChange',
  },
  {
    path: '/agreements',
    exact: true,
    configName: 'agreementsUpdate',
  },
  {
    path: '/sbbol-premium-km',
    exact: true,
    configName: 'sbbolPremiumKm',
  },
  {
    path: '/biz-adaptation',
    exac: true,
    configName: 'bizAdaptation',
  },
  {
    path: '/sbbol-result-120sec',
    component: Sbbol120SecProvider,
    exact: true,
  },
  {
    path: '/sbbol-result-premium',
    component: SbbolPremiumProvider,
    exact: true,
  },
  {
    path: '/sbbol-result-115fz',
    component: Sbbol115FZProvider,
    exact: true,
  },
  {
    path: '/sbbol-result-lms',
    component: SbbolLMSProvider,
    exact: true,
  },
]

const developRoutes = [
  {
    path: '/develop',
    exact: true,
    configName: 'loginFrame',
  },
]

// TODO: developRoutes попадает в бандл, нужно это поправить
export default env === 'prod' ? defaultRoutes : defaultRoutes.concat(developRoutes)
