import { media, Paragraph, Title } from '@dasreda/components'
import styled, { css } from 'styled-components'

import { MailProviderButton } from 'components'

import SubmitButton from '../FormBuilder/components/SubmitButton'

export const SThankYouWrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 85px 24px;
    text-align: center;

    ${media.md} {
      padding: clamp(120px, 285px, 23vh) 24px;
    }

    ${media.lg} {
      width: 877px;
      padding: clamp(80px, 155px, 15vh) 0;
    }

    ${media.xl} {
      padding: clamp(120px, 255px, 20vh) 0;
    }

    ${media.xxl} {
      padding: clamp(140px, 280px, 25vh) 0;
    }
  `
)

export const SMailProviderButton = styled(MailProviderButton)(
  () => css`
    margin: 16px 0 48px;
  `
)

export const SThankYouTitle = styled(Title)(
  ({ theme }) => css`
    color: ${theme.palette.emerald};
  `
)

export const SThankYouSubtitle = styled(Paragraph)(
  ({ theme }) => css`
    margin-top: 16px;
    margin-bottom: 30px;
    color: ${theme.palette.textGray};
  `
)

export const SThankYouDescription = styled(Paragraph)(
  () => css`
    max-width: 591px;
    margin-top: 24px;
  `
)

export const SButton = styled(SubmitButton)(
  ({ theme, disabled }) => css`
    width: unset;
    min-width: 195px;
    color: ${disabled && theme.palette.emerald};
  `
)
