export const isValidUrl = (urlString: string) => {
  try {
    const validUrl = new URL(urlString)

    if (!validUrl.origin) {
      return false
    }
    return /^https?:\/\/([A-z-.\d]+\.)?dasreda\.ru(\/[^ ]*)?$/.test(urlString)
  } catch {
    return false
  }
}
