import { FC } from 'react'
import { Title } from '@dasreda/components'

import { IThankYouProps } from './types'

import { useBase } from './useBase'

import {
  SButton,
  SMailProviderButton,
  SThankYouDescription,
  SThankYouSubtitle,
  SThankYouTitle,
  SThankYouWrapper,
} from './styled.index'

const ThankYou: FC<IThankYouProps> = (props) => {
  const { repeatSendConfirmation, seconds, isTimeOver, loading, emailProvider, isAttemptsOver, redirectToAuth } =
    useBase(props)

  if (isAttemptsOver) {
    return (
      <SThankYouWrapper>
        <Title level={3}>
          Вы исчерпали возможность повторной отправки сообщения для подтверждения почты. <br />
          Обратитесь в тех.поддержку <a href="mailto:support@dasreda.ru">support@dasreda.ru</a>
        </Title>
        <SButton onClick={redirectToAuth}>Вернуться к авторизации</SButton>
      </SThankYouWrapper>
    )
  }

  return (
    <SThankYouWrapper>
      <SThankYouTitle level={0}>Остался всего один шаг</SThankYouTitle>
      <SThankYouSubtitle level={1}>
        Подтвердите почту и пользуйтесь функциями, доступными только зарегистрированным пользователям
      </SThankYouSubtitle>
      {emailProvider && <SMailProviderButton emailDomain={emailProvider} />}
      <Title level={3}>Отправить письмо с подтверждением ещё раз</Title>
      <SButton disabled={!isTimeOver || loading} onClick={repeatSendConfirmation} size="small">
        {isTimeOver ? 'Отправить письмо' : seconds}
      </SButton>
      <SThankYouDescription level={3}>
        Обычно письмо приходит в течение пары минут. Нет письма? Проверьте папку &quot;спам&quot;, если его нет и там,
        то напишите нам на <a href="mailto:support@dasreda.ru">support@dasreda.ru</a> - мы вам поможем
      </SThankYouDescription>
    </SThankYouWrapper>
  )
}

export default ThankYou
