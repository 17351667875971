import { FC } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { IFooter } from './types'

import { logout } from 'utils/auth'

import CoopAgree from './CoopAgree'
import DsAgree from './DsAgree'
import { SFormFooter, SFormFooterLink, SFormFooterLinkGreen } from './styled.index'

interface IConfigMethodOptions {
  search?: string
  actionType?: IFooter['actionType']
}

export const config = {
  forgotPassword: ({ search }: IConfigMethodOptions) => (
    <SFormFooterLink to={{ pathname: '/password-reset-request', search }}>Забыли пароль?</SFormFooterLink>
  ),
  forgotPasswordPhone: ({ search }: IConfigMethodOptions) => (
    <SFormFooterLink to={{ pathname: '/password-reset-request/phone', search }}>Забыли пароль?</SFormFooterLink>
  ),
  agree: () => (
    <SFormFooterLink to="/" onClick={logout}>
      Отказаться от условий
    </SFormFooterLink>
  ),
  dsAgree: ({ actionType }: IConfigMethodOptions) => {
    switch (process.env.REACT_APP_BUILD) {
      case 'coop':
        return <CoopAgree />
      default:
        return <DsAgree actionType={actionType} />
    }
  },
  resetPassword: ({ search }: IConfigMethodOptions) => (
    <SFormFooterLinkGreen to={{ pathname: '/login', search }} onClick={logout}>
      Вернуться к авторизации
    </SFormFooterLinkGreen>
  ),
  resetPasswordPhone: ({ search }: IConfigMethodOptions) => (
    <SFormFooterLinkGreen to={{ pathname: '/login-by-phone', search }}>Вернуться к авторизации</SFormFooterLinkGreen>
  ),
}

const Footer: FC<IFooter & RouteComponentProps> = ({ type, align = 'center', location, actionType }) => {
  const { search } = location
  const content = config[type]({ search, actionType })

  return <SFormFooter align={align}>{content}</SFormFooter>
}

export default withRouter(Footer)
