import { IFlexValidator, TValidator, TValues } from 'containers/FormBuilder/components/Field'
import { generateMaxLengthValidator, generateMinLengthValidator } from 'utils/validatorsGenerators'

import valid from './valid'

export const required = <TCustomErrorMessage extends string | undefined>(
  value?: string,
  _?: TValues,
  customErrorMessage?: TCustomErrorMessage
): ReturnType<IFlexValidator<string, TCustomErrorMessage>> => {
  if (customErrorMessage) {
    return ((_value: string) =>
      _value ? undefined : customErrorMessage) as unknown as TCustomErrorMessage extends string
      ? TValidator<string>
      : never
  }

  return (value ? undefined : 'Поле обязательно к заполнению') as TCustomErrorMessage extends string
    ? never
    : ReturnType<TValidator<string>>
}

export const onlyCyrillicText = (value: string) => {
  if (!/^[-а-яА-ЯЁё]+(\s+[-а-яА-ЯЁё]+)*$/.test(value?.trim())) {
    return 'Разрешены только русские буквы'
  }

  return undefined
}

export const minLength = generateMinLengthValidator(2)

export const maxLength = generateMaxLengthValidator(24)

export const shouldHasLowercaseLatinSymbol = (value?: string) =>
  value && value.match(/(?=.*[a-z])/) ? undefined : 'Должно содержать строчные латинские буквы'

export const shouldHasUppercaseLatinSymbol = (value?: string) =>
  value && value.match(/(?=.*[A-Z])/) ? undefined : 'Должно содержать прописные латинские буквы'

export const shouldHasNumberSymbol = (value?: string) =>
  value && value.match(/(?=.*\d)/) ? undefined : 'Должно содержать цифры'

export const shouldNotHasSpecialSymbols = (value?: string) =>
  // eslint-disable-next-line no-control-regex
  value && value.match(/^[0-9a-zA-Z]+$/) && /^[\x00-\x7F]*$/.test(value)
    ? undefined
    : 'Не должно содержать спецсимволы или кириллицу'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const equal = (value: string, allValues: { [key: string]: any }, key: string, message?: string) =>
  value === allValues[key] ? undefined : message || 'Значения полей должны совпадать'

export const email = (value: string) => (valid.email(value) ? undefined : 'Введите корректный адрес эл. почты')

export const emailNotRequired = (value: string) =>
  value && !valid.email(value) ? 'Введите корректный адрес эл. почты' : undefined

export const passwordStrongValidator = (value: string) => {
  return valid.passwordStrong(value)
    ? undefined
    : 'От 6 до 64 символов, пароль должен содержать только цифры, прописные и строчные буквы латиницей'
}

export const passwordSimpleValidator = (value: string) => {
  return valid.passwordSimple(value)
    ? undefined
    : 'От 6 до 64 символов, пароль должен содержать цифры и буквы латиницей'
}

export const inn = (value: string) =>
  valid.inn(value) ? undefined : 'Поле должно содержать корректный номер ИНН (10 или 12 цифр)'

export const phone = (value: string) =>
  valid.phone(value) ? undefined : 'Поле должно содержать корректный номер телефона'

export const phoneNotRequired = (value: string) =>
  value && !valid.phone(value) ? 'Поле должно содержать корректный номер телефона' : undefined

export const antiFraudPhone = (value: string) => {
  const fraudRegExpStrings = [
    /^(?!.*(\d)\1{6,})/, // в значении не более 6-ти идущих подряд цифр
    /^\d[3,4,8,9]\d{9}$/, // код региона начинается правильно
  ]
  // slice нужен, чтобы при попытке ввести лишние символы они не попадали в значение поля
  const phoneValue = value?.slice(0, 11).replace(/[^\d]/g, '')

  for (let i = 0; i < fraudRegExpStrings.length; i += 1) {
    const regExp = new RegExp(fraudRegExpStrings[i])

    if (!regExp.test(phoneValue)) {
      return 'Поле должно содержать корректный номер телефона'
    }
  }

  return undefined
}

export const personnelNumber = (value: string) =>
  valid.number(value) && value.length === 8 ? undefined : 'Поле может быть только 8-ми значным и цифирным.'

/** Валидатор для проверки, удовлетворяет ли поле условиям парольной политики.
 * Валидация не пройдена, если в поле присутствуют какие-либо символы кроме:
 * 1) заглавных латинских A-Z
 * 2) строчных латинских a-z
 * 3) цифр
 * 4) специальных символов
 * */
export const passwordPolicyCheckForbiddenChars = (value: string) => {
  const forbiddenCharsRegex = /[^a-zA-Z0-9.!@#$%^&*()_+{}|:`<>?\-[\];,,\s]/
  const checkedRegexResult = value.match(forbiddenCharsRegex)

  const forbiddenChars = checkedRegexResult?.[0]

  if (forbiddenChars && forbiddenChars.length > 0) {
    return `Пароль содержит недопустимый символ "${forbiddenChars}"`
  }

  return undefined
}

export const passwordPolicyCheckCyrillic = (value: string) => {
  const cyrillicRegex = /[а-яА-ЯёЁ]/

  if (cyrillicRegex.test(value)) {
    return 'Пароль не должен содержать кириллицу'
  }

  return undefined
}

export const threeStepPasswordPolicyValidator = (value: string) => {
  const lowerCaseRegex = /[a-z]/
  const upperCaseRegex = /[A-Z]/
  const digitRegex = /[0-9]/
  const specialCharRegex = /[.!@#$%^&*()_+{}|:`/<>?\-[\];,,\s]/

  let passedCategories = 0

  if (lowerCaseRegex.test(value)) {
    passedCategories += 1
  }

  if (upperCaseRegex.test(value)) {
    passedCategories += 1
  }

  if (digitRegex.test(value)) {
    passedCategories += 1
  }

  if (specialCharRegex.test(value)) {
    passedCategories += 1
  }

  if (passedCategories < 3) {
    let message = 'Пароль недостаточно сложный. Добавьте:'

    if (!lowerCaseRegex.test(value)) {
      message += ' буквы a-z,'
    }

    if (!upperCaseRegex.test(value)) {
      message += ' буквы A-Z,'
    }

    if (!digitRegex.test(value)) {
      message += ' цифры,'
    }

    if (!specialCharRegex.test(value)) {
      message += ' спецсимволы'
    }

    return message.replace(/,+$/, '')
  }
  return undefined
}
