import { TRequest } from 'types/api'

import { Header } from 'components'
import { TFormConfig } from 'containers/FormBuilder'
import { TValues } from 'containers/FormBuilder/components/Field'
import { SFieldInput } from 'containers/FormBuilder/styled.index'
import { request } from 'utils/api'
import { redirect } from 'utils/redirect'
import { transformError } from 'utils/transformError'
import { equal, passwordPolicyCheckForbiddenChars, required, threeStepPasswordPolicyValidator } from 'utils/validators'
import { generateMinLengthValidator } from 'utils/validatorsGenerators'

import { passwordFieldProps, passwordRepeatFieldProps } from '../../defaultFieldsProps'

// TODO: продумать ошибку со скрытым полем uuid
const passwordChangePhone: TFormConfig = {
  title: 'Смена пароля',
  header: <Header type="password-change-phone" />,
  submitButtonText: 'Сохранить новый пароль',
  fields: [
    {
      name: 'phone',
      type: 'hidden',
      component: SFieldInput,
      qa: 'password_uuid_field',
    },
    {
      name: 'code',
      placeholder: 'Код из СМС',
      component: SFieldInput,
      qa: 'password_uuid_field',
    },
    {
      ...passwordFieldProps,
      validators: [
        required,
        generateMinLengthValidator(12),
        passwordPolicyCheckForbiddenChars,
        threeStepPasswordPolicyValidator,
      ],
    },
    {
      ...passwordRepeatFieldProps,
      validators: [required, (value, allValues: TValues) => equal(value, allValues, 'password')],
    },
  ],
  successMessage: 'Вы успешно сменили пароль',
  onRequest: async (values) => {
    const config: TRequest = {
      location: 'core-router/v2:user/restore/phone/confirm',
      method: 'post',
      params: {
        data: values,
      },
    }

    return request(config)
  },

  postHook: async (_error, _response, { initialValues: { phone }, history: { push } }) => {
    if (_error) {
      return { ...transformError(_error) }
    }

    return redirect({ push, url: `/login-by-phone?username=${phone}` })
  },
}

export default passwordChangePhone
