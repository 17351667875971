import { useState } from 'react'
import getApolloClient from 'gql/helpers/getApolloClient'
import { loader } from 'graphql.macro'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'

import DEFAULT_AGREEMENT_TYPES from 'configs/agreementTypes'

import { DUMMY_AGREEMENT } from 'configs/agreements'
import CHANNEL_IDS from 'configs/channels'
import { dummyUser } from 'configs/consts'
import { TFormConfig } from 'containers/FormBuilder'
import SubmitButton from 'containers/FormBuilder/components/SubmitButton'
import { transformAgreements } from 'utils/agreements'
import { request, setAuthData } from 'utils/auth'
import { sendNewUserToGTR } from 'utils/getResponse'
import { commonRegistrationActions } from 'utils/registration'
import {
  antiFraudPhone,
  email as emailValidator,
  inn as innValidator,
  phone as phoneValidator,
  required,
} from 'utils/validators'

import useSendSmsToTemplateSlug from 'hooks/useSendSmsToTemplateSlug'

import {
  emailFieldProps,
  innFieldProps,
  nameFieldProps,
  phoneFieldProps,
  surnameFieldProps,
} from '../../defaultFieldsProps'

const userEnrollMutation = loader('src/gql/mutations/userEnroll.gql')

const SMS_TEMPLATE_SLUG = 'startuj-uverenno-oktyabr'
const LMS_GROUP_CODE = 'GBqPA1L28ZX8DCclRKBSh'
const channelId = CHANNEL_IDS.LMS

const SubmitBlock: TFormConfig['submitBlock'] = ({
  hasValidationErrors,
  agreementDocuments,
  form,
  setError,
  setSucceeded,
}) => {
  const [loading, setLoading] = useState(false)
  const { values: formValues } = form.getState()

  let nextData = cloneDeep(formValues)
  DEFAULT_AGREEMENT_TYPES.forEach((agreementType) => {
    nextData[agreementType] = true
  })
  nextData = transformAgreements(nextData, agreementDocuments)

  const buttonDisabled = hasValidationErrors

  const sendSmsToTemplateSlug = useSendSmsToTemplateSlug(SMS_TEMPLATE_SLUG)

  const userEnroll = async (email: string, program: string) => {
    const apolloClient = await getApolloClient

    return apolloClient.mutate({
      mutation: userEnrollMutation,
      variables: {
        params: {
          email,
          program,
        },
      },
      fetchPolicy: 'network-only',
    })
  }

  const submitHandler = async () => {
    const { values } = form.getState()
    const { username: email, phone, firstName, lastName, inn } = values

    setError(null)
    setSucceeded(null)
    setLoading(true)

    try {
      const config = {
        location: `core-router/v2:register/${channelId}`,
        params: {
          data: {
            ...dummyUser,
            email,
            lastName,
            firstName,
            phone,
            inn,
            ...nextData,
            isFirstLogin: true,
            channelId,
          },
        },
      }

      const { data } = await request(config)

      if (data) {
        setAuthData({ data })
        sendSmsToTemplateSlug(`+${phone}`)
        commonRegistrationActions()

        const { publicName, agreements } = data

        await sendNewUserToGTR(
          { email, firstName, lastName, publicName, agreements, channelId },
          {
            isFastRegChannel: true,
            campaignId: 'TL',
            customOptions: {
              phone_1: phone,
            },
          }
        )

        userEnroll(email, LMS_GROUP_CODE)
      }
      form.restart()
      setSucceeded(true)
    } catch (e) {
      const errorCode = get(e, 'response.data.errors[0].code')
      if (errorCode === 'EXECUTION_ERROR') {
        sendSmsToTemplateSlug(`+${phone}`)
        commonRegistrationActions()

        await sendNewUserToGTR(
          { email, firstName, lastName, channelId },
          {
            isFastRegChannel: true,
            campaignId: 'TL',
            customOptions: {
              phone_1: phone,
            },
          }
        )

        userEnroll(email, LMS_GROUP_CODE)

        form.restart()
        setSucceeded(true)
      } else {
        setError(get(e, 'response.data.errors[0]._error', 'Произошла неизвестная ошибка, попробуйте еще раз'))
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <SubmitButton type="button" onClick={submitHandler} color="textGray" disabled={buttonDisabled || loading}>
      {loading ? 'Выполняется запрос' : 'Принять участие'}
    </SubmitButton>
  )
}

const mspRegistrationBotAugust: TFormConfig = {
  title: 'Начните прокачку бизнеса на интенсиве!',
  formWidth: 520,
  submitButtonText: 'Принять участие',
  initialValues: {},
  fields: [
    {
      ...nameFieldProps,
      validators: [required, ...(nameFieldProps.validators as [])],
    },
    {
      ...surnameFieldProps,
      validators: [required, ...(surnameFieldProps.validators as [])],
    },
    {
      ...emailFieldProps,
      validators: [required, emailValidator],
    },
    {
      ...phoneFieldProps,
      validators: [required, phoneValidator, antiFraudPhone],
    },
    {
      ...innFieldProps,
      validators: [required, innValidator],
    },
    {
      name: DUMMY_AGREEMENT,
      component: () => (
        <span>
          Нажимая на кнопку «Принять участие», вы даете{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://cdn.dasreda.ru/fonts-static/soglasie-pd.pdf">
            Согласие на обработку персональных данных
          </a>{' '}
          и принимаете{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://cdn.dasreda.ru/storage-data/7577f37a-d9d6-412d-8358-0fd7423929b6/politika_obrabotki_pdn_30062023.pdf"
          >
            Политику конфиденциальности
          </a>
        </span>
      ),
      qa: 'dispatchMspBizAgree_checkbox',
      validators: [],
    },
  ],
  successMessage: 'Сообщение отправлено!',
  agreementTypesToLoad: DEFAULT_AGREEMENT_TYPES,
  submitBlock: SubmitBlock,
  onRequest: () => Promise.resolve(true),
}

export default mspRegistrationBotAugust
