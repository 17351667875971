import { Cookies } from 'react-cookie'
import getApolloClient from 'gql/helpers/getApolloClient'
import { loader } from 'graphql.macro'
import get from 'lodash/get'
import merge from 'lodash/merge'
import { TRequest } from 'types/api'
import { TResUser, TUser } from 'types/user'

import { configs, helpers } from 'eco-api'

import { getUserAgreements } from './agreements'
import { request } from './api'

const { AUTH_COOKIE_NAME, AUTH_COOKIE_DOMAIN } = configs
const { tokenIsValid, tokenHasExpired, decodeToken } = helpers

const membershipsGQL = loader('src/gql/queries/memberships.gql')
const checkShareholderInnGQL = loader('src/gql/mutations/checkShareholderInn.gql')
const checkShareholderLastNameGQL = loader('src/gql/mutations/checkShareholderLastName.gql')

const getUserByStorage = () => JSON.parse(localStorage.getItem('user') || '{}')

const setUserToStorage = (user: Omit<TUser, 'token'>) => localStorage.setItem('user', JSON.stringify(user))

const setAuthData = ({ data }: TResUser) => {
  const { token = '', ...user } = data
  const decodedToken = decodeToken(token)

  if (decodedToken) {
    const cookies = new Cookies()
    cookies.set(AUTH_COOKIE_NAME, token, {
      domain: AUTH_COOKIE_DOMAIN,
      path: '/',
      // eslint-disable-next-line @typescript-eslint/ban-types
      expires: new Date((decodedToken.exp as number) * 1000), // Время жизни берем из токена
      secure: true,
    })

    setUserToStorage(user)
  }
}

const getUserByToken = () => {
  try {
    const cookies = new Cookies()
    const token = cookies.get(AUTH_COOKIE_NAME)
    if (!token || !tokenIsValid(token) || tokenHasExpired(token)) return {}
    return decodeToken(token)
  } catch (e) {
    return {}
  }
}

const getPersonalData = (id: string | number) =>
  request({
    location: `core-personal:personal/${id}`,
    method: 'get',
  })

// TODO: add typedefs for user input
// eslint-disable-next-line @typescript-eslint/ban-types
const updateUserPesonalData = (userData: object) =>
  request({
    location: `core-personal:personal`,
    method: 'put',
    params: {
      data: userData,
    },
  })

// TODO: add typedefs for user input
// eslint-disable-next-line @typescript-eslint/ban-types
const updateUserData = (userData: object, requestParams?: TRequest['params']) => {
  const { id } = userData as TUser

  return request({
    location: `core-router/v2:user/${id}`,
    method: 'put',
    params: {
      ...requestParams,
      data: userData,
    },
  })
}

const login = async (config: TRequest) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const userPublicData = await request(config)

    const user = get(userPublicData, 'data') || {}
    const { id: userId } = user

    // before getting sensitive data we should set token to LS for axios instance
    await setAuthData({ data: user })

    const personalData = await getPersonalData(userId)

    const userAgreements = await getUserAgreements()

    const fullUserData = merge(user, get(personalData, 'data', {}), {
      agreements: get(userAgreements, 'data', []),
    })

    return { data: fullUserData }
  } catch (error) {
    throw error
  }
}

const logout = () => {
  const cookies = new Cookies()

  cookies.remove(AUTH_COOKIE_NAME, {
    domain: AUTH_COOKIE_DOMAIN,
    path: '/',
  })
  localStorage.removeItem(AUTH_COOKIE_NAME)
  localStorage.removeItem('user')
}

const getMemberships = async () => {
  const apolloClient = await getApolloClient
  const { data } = await apolloClient.query({
    query: membershipsGQL,
  })

  return data?.memberships?.result || []
}

const checkShareholderInn = async (coopInn: string, inn: string, token: string) => {
  const apolloClient = await getApolloClient

  const { data } = await apolloClient.mutate({
    mutation: checkShareholderInnGQL,
    variables: {
      coopInn,
      inn,
    },
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  })

  return data?.checkShareholderInn?.result || false
}

const checkShareholderLastName = async (
  coopInn: string,
  lastName: string,
  firstName: string,
  middleName: string,
  token: string
) => {
  const apolloClient = await getApolloClient

  const { data } = await apolloClient.mutate({
    mutation: checkShareholderLastNameGQL,
    variables: {
      coopInn,
      lastName,
      firstName,
      middleName,
    },
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  })

  return data?.checkShareholderLastName?.result || false
}

const removeCookie = () => {
  const cookies = new Cookies()
  cookies.remove(AUTH_COOKIE_NAME, {
    domain: AUTH_COOKIE_DOMAIN,
    path: '/',
  })
}

const addCookie = (token: string) => {
  const decodedToken = decodeToken(token)
  if (decodedToken) {
    const cookies = new Cookies()
    cookies.set(AUTH_COOKIE_NAME, token, {
      domain: AUTH_COOKIE_DOMAIN,
      path: '/',
      // eslint-disable-next-line @typescript-eslint/ban-types
      expires: new Date((decodedToken.exp as number) * 1000), // Время жизни берем из токена
      secure: true,
    })
  }
}

export {
  setAuthData,
  getUserByStorage,
  setUserToStorage,
  getUserByToken,
  request,
  login,
  logout,
  updateUserData,
  getPersonalData,
  getMemberships,
  updateUserPesonalData,
  checkShareholderInn,
  checkShareholderLastName,
  removeCookie,
  addCookie,
}
