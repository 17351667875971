import { FC, useEffect, useState } from 'react'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { YMInitializer } from 'react-yandex-metrika'
import { GlobalStyle, Header } from '@dasreda/components'
import get from 'lodash/get'

import { configs } from 'eco-api'

import { Error404 } from 'components'
import { REDIRECT_URL } from 'configs/consts'
import formConfig from 'configs/forms'
import appRoutes from 'routes'
import { getYandexMetrikaCounters, shouldUseYandexMetrika } from 'utils/yandexMetrika'

import FormBuilder from '../FormBuilder'

import { SAppWrapper } from './styled.index'

const { resolvePortalUrl } = configs

const YMComponent: FC = () => {
  const shouldRender = shouldUseYandexMetrika()
  const counters = getYandexMetrikaCounters()

  if (!shouldRender || !counters) return null

  return (
    <YMInitializer
      accounts={counters}
      version="2"
      options={{
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        trackHash: true,
      }}
    />
  )
}

const App: FC<RouteComponentProps> = ({ location }) => {
  const [expanded, setExpanded] = useState(false)
  const isInIframe = window.self !== window.top
  const showHeader = !location.pathname.match(/registration-domclick/) && !isInIframe

  useEffect(() => {
    if (isInIframe) {
      const el = document?.querySelector('html')
      if (el) {
        el.style.overflow = 'hidden'
      }
    }
  }, [])

  useEffect(() => {
    const externalRedirect = get(location, 'query.redirect')

    if (externalRedirect) {
      localStorage.setItem(REDIRECT_URL, externalRedirect)
    } else {
      const excludePaths = ['/sbbol-result', '/sbol-result', '/esia-result']

      if (!excludePaths.includes(location.pathname)) {
        localStorage.removeItem(REDIRECT_URL)
      }
    }
  }, [])

  const expand = () => setExpanded(true)

  return (
    <SAppWrapper>
      <GlobalStyle />
      {showHeader && (
        <Header
          to={resolvePortalUrl()}
          menuItems={[]}
          actionItems={[]}
          cartWithProducts={false}
          authLink="/"
          registerLink="/"
          profileLink="/"
          activeMenu=""
          applyPromoCodeProps={{}}
          sbbolMenuItems={[]}
          isLogoOnly
          logoType={process.env.REACT_APP_BUILD === 'coop' ? 'coop' : 'dasreda'}
        />
      )}
      <Switch>
        {appRoutes.map(({ path, exact, configName, component: BasicComponent }) => (
          <Route
            key={path}
            exact={exact}
            path={path}
            render={(props) =>
              BasicComponent ? (
                <BasicComponent {...props} />
              ) : (
                <FormBuilder
                  expand={expand}
                  expanded={expanded}
                  {...{
                    ...props,
                    ...(typeof formConfig[configName] === 'object'
                      ? formConfig[configName]
                      : formConfig[configName](props)),
                  }}
                />
              )
            }
          />
        ))}

        <Route exact component={Error404} />
      </Switch>

      <YMComponent />
    </SAppWrapper>
  )
}

export default withRouter(App)
