import { FC } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { ISbbolResult } from '../types'

import { configs } from 'eco-api'

import { COURSE_LS_KEY, OTR_TYPE_LS_KEY, SBBOL_LMS } from 'configs/consts'
import ResultPage from 'containers/OAuth/components/ResultPage'
import onLoadSbbol from 'containers/OAuth/utils/onLoadSbbol'

const { resolvePortalUrl } = configs

const REDIRECT_URL = `${resolvePortalUrl()}showcase/enroll/2`
const REDIRECT_URL_RETAIL = `${resolvePortalUrl()}showcase/enroll/2?category=5`
const REDIRECT_URL_SELLER = `${resolvePortalUrl()}showcase/enroll/2?category=12`

type TOtrType = string | null
type TCourse = string | null

const getRedirectUrlByCourseValue = (courseValue: TCourse) => `${resolvePortalUrl()}lms/otr?code=${courseValue}`

const getRedirectUrlByOtrType = (otrType: TOtrType) => {
  switch (otrType) {
    case 'retail':
      return REDIRECT_URL_RETAIL
    case 'seller':
      return REDIRECT_URL_SELLER
    default:
      return REDIRECT_URL
  }
}

const getRedirectUrl = (params: { otrType: TOtrType; course: TCourse }) => {
  if (params.course) {
    return getRedirectUrlByCourseValue(params.course)
  }

  return getRedirectUrlByOtrType(params.otrType)
}

const SbbolPremiumResult: FC<ISbbolResult & RouteComponentProps> = ({
  location: {
    query: { code },
  },
  history: { push },
}) => {
  const otrType = localStorage.getItem(OTR_TYPE_LS_KEY)
  const course = localStorage.getItem(COURSE_LS_KEY)

  const redirectUrl = getRedirectUrl({ otrType, course })

  return <ResultPage onLoad={onLoadSbbol({ code, push, product: SBBOL_LMS, redirectUrl })} />
}

export default SbbolPremiumResult
