import { FieldValidator } from 'final-form'

type TValidateFn<FieldValue> = (...args: Parameters<FieldValidator<FieldValue>>) => string | undefined

export const generateMinLengthValidator =
  (minLengthNumber: number, errorMessage?: string): TValidateFn<string> =>
  (value) => {
    if (value && value?.length < minLengthNumber) {
      return errorMessage || `Поле должно содержать ${minLengthNumber} или более символов`
    }

    return undefined
  }

export const generateMaxLengthValidator =
  (maxLengthNumber: number, errorMessage?: string): TValidateFn<string> =>
  (value) => {
    if (value && value?.length > maxLengthNumber) {
      return errorMessage || `Поле должно содержать не более ${maxLengthNumber} символов`
    }

    return undefined
  }
